// const { deflateRaw } = require('zlib');

//env = identificationProcessAPI2URL: "https://test-au.vixverify.com/Registrations-Registrations/DynamicFormsServiceV3"
function greenID(token, username, pwd, env) {

	greenidUI.setup({
		environment: env,
		frameId: 'greenid-div',
		errorCallback: onError,
		enableCancelButton: true,
		sessionCompleteCallback: onSessionComplete,
		sourceAttemptCallback: onSourceAttempt,
		sessionCancelledCallback: onSessionCancel,
		preSubmitValidationCallback: myValidator,
		submitCallback: onSubmit
	});

	greenidUI.show(username, pwd, token);
}

function onRegister(value) {
	//////--------------1
	// alert(value + ' ' + 'Register');
}

function onError(value) {
	// alert('Error');
}

function onSessionComplete(value, state) {
	/////////////------4
	// window.angularComponentSessionComplete.zone.run(() => {
	// 	window.angularComponentSessionComplete.loadAngularFunction();
	// });
	//alert(value + ' ' + 'Session Complete');
}
function onSourceAttempt(value) {
	////-------------------2  ------3
	// alert(value + ' ' + 'Source Attempt');
}
function onSessionCancel(value, state) {
	window.angularComponentSessionCancel.zone.run(() => {
		window.angularComponentSessionCancel.loadAngularFunction();
	});
}
function onSubmit(value, state) {
	localStorage.setItem('SubmitState', state);
	window.angularComponentReference.zone.run(() => {
		window.angularComponentReference.loadAngularFunction();
	});
}

function myValidator() {
	var bool;
	window.angularComponentValidation.zone.run(() => {
		bool = window.angularComponentValidation.loadAngularFunction();
	});

	return bool;
}